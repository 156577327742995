import React from "react"
import SEO from "../components/seo"
import aboutStyles from "../styles/about.module.scss"
import Layout from "../components/layout"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronCircleRight} from '@fortawesome/free-solid-svg-icons'


const About = ((props) => {
  return (
    <Layout>
      <SEO title="About"/>
      <div className={aboutStyles.maindiv}>
        <div className={aboutStyles.headerdiv}>OUR STORY</div>
        <div className={aboutStyles.textdiv}>
            Lady On Wheels Cleaning Services founded in 2014 as a Laundry Services company. 
            Our primary service value is to always put our clients first in all we do. 
            Over the years as we were servicing the laundry sector, our clients requested the service of Cleaning from time to time. 
            We identified a need that had to be fulfilled for our clients as most of our clients required additional cleaning services as well. 
            Having been impressed by the quality of our laundry services that we offered to all our clients. 
            The increased demand from our clients led to the expansion of our services and Lady On Wheels Cleaning Services was born. 
            Our cleaning services cover both commercial and residential services. 
            Our cleaning services include contract janitorial cleaning for commercial premises, residential premises which can be, 
            once off or frequent cleaning and we still offer the best carpet and upholstery cleaning services.
        </div>
        <div className={aboutStyles.contacthead}>FOR MORE INFOMATION:</div>
        <div className={aboutStyles.contactdiv}>
            <a href="/contact">CONTACT</a>
        </div>
      </div>
    </Layout>
  )
}
)

export default About